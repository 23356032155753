import * as React from "react";
import * as PropTypes from "prop-types";
import $ from "jquery";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../../redux/actions";
import getLocationInfo from "../../../../lib/getLocationInfo";
import {isEnvironmentAdmin} from "../../../../lib/user-environment-permissions-helpers";
import {Link} from "react-router-dom";
import withLegacyTheme from "../../../../lib/hoc/with-legacy-theme";
import {CardsContainer, CardWrapper, CardBody, CardBodyAddNew, CardMenuImage, CardCustomContent} from "../../../Widgets/CustomCard/";
import {Avatar, Chip} from "@material-ui/core";
import CenteredCircularProgress from "../../../Widgets/CenteredCircularProgress/";
import {NavbarL2} from "../../../CustomNavbar/";
import Dialogs from "./Dialogs/";
import {Delete, Visibility, Label} from "@material-ui/icons";
import "./style.less";
import InitMachine from './InitMachine'

export const INIT_STATE = {
    status: "", // "" | "ready" | "init" | "wait" | "w-init"

    data: {
        clientAuthenticationMethod: 'NONE',
        requireProofKey: false,
        wellKnownJwksJson: null,
        accessTokenTTLSeconds: 1800,
    },
    dataValidation: {},

    dialog: "",

    delete: {
        appId: "",
        name: "",
        clientId: ""
    },

    wstep: 1,
    isNextWStepEnabled: false,

    appsNoActivations: [],
};

class Component extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        login: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired,
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        $(".stage").addClass("stage-bgnd-dark");
        this.init()
    }

    public componentWillUnmount() {
        $(".stage").removeClass("stage-bgnd-dark");
    }

    public render() {
        const {env} = getLocationInfo(this.props.location, this.props.config);

        const actAsAdmin: boolean = isEnvironmentAdmin(this.props.login, this.props.location, this.props.config);

        const data = [...this.state.appsNoActivations]
            .sort((a, b) => a.name.localeCompare(b.name))

        return <div className="apps">
            <InitMachine
                parentState={this.state as any}
                onSetParentState={this.onSetState}
            />

            <NavbarL2 title={
                <Link to={`/${env}/apps`} style={{color: "#fff", fontSize: "24px", textDecoration: "none"}}>
                    Apps
                </Link>}/>

            <div className="container" style={{paddingTop: "48px"}}>
                <p style={{margin: "0 0 15px 0", color: this.props.muiTheme.palette.textColor, fontSize: "24px", fontWeight: 500, lineHeight: "32px"}}>
                    interopiO® App Management Console
                </p>
                <p style={{width: "350px", margin: "0 0 15px 0", color: this.props.muiTheme.palette.secondaryTextColor, fontSize: "16px", fontWeight: 400, lineHeight: "20px"}}>
                    Powerful tools to distribute, activate, monitor, and analyze SMART™ on FHIR® Apps.
                </p>
                <p style={{margin: "0 0 60px 0"}}>
                    <a href="https://support.interopio.com/hc/en-us/categories/360001801431-Getting-Started" target="_blank" style={{margin: "0 15px 0 0"}}>
                        Getting Started
                    </a>
                    <a href="https://support.interopio.com/hc/en-us/categories/360001807451-Using-interopiO-" target="_blank" style={{margin: "0 15px 0 0"}}>
                        Documentation
                    </a>
                    <a href="https://support.interopio.com/hc/en-us" target="_blank" style={{margin: "0 15px 0 0"}}>
                        Support
                    </a>
                </p>

                {["ready", "wait", "w-init"].indexOf(this.state.status) < 0
                    ? <CenteredCircularProgress size={63}/>
                    : <CardsContainer>
                        {actAsAdmin
                            ? <CardWrapper style={{width: "308px", height: "307px", margin: "0 21px 17px 0", borderRadius: "14px", paddingBottom: "24px"}} data-qa="new-app-card">
                                <CardBodyAddNew style={{height: "307px", padding: "110px 0"}} text="Register App" onClick={() => this.setState({dialog: ":ADD_NEW_WIZARD:"})}/>
                            </CardWrapper>
                            : null}

                        {data.map((item, index) =>
                            <CardWrapper data-qa={`app-card-${item.appId}`} key={`card-${index}-${item.appId}`}
                                style={{width: "308px", height: "307px", margin: "0 21px 17px 0", borderRadius: "14px", paddingBottom: "24px"}}>
                                <CardMenuImage data-qa={`app-context-menu-${item.appId}`} config={this.props.config} src={item.logoUrl || "/console/img/card-media-apps.png"}
                                    style={{height: "133px", borderRadius: "12px 12px 0 0"}} onClick={() => this.props.history.push(`/${env}/apps/${item.appId}`)}
                                    menuItems={actAsAdmin
                                        ? [
                                            {
                                                leftIcon: <Visibility style={{height: "18px", width: "18px", margin: "4px"}}/>,
                                                text: "View",
                                                onClick: () => this.props.history.push(`/${env}/apps/${item.appId}`)
                                            },
                                            {
                                                "data-qa": "app-delete-btn",
                                                leftIcon: <Delete style={{height: "18px", width: "18px", margin: "4px"}}/>,
                                                text: "Delete",
                                                dividerTop: true,
                                                onClick: () => this.setState({
                                                    dialog: ":DELETE_CONFIRM:",
                                                    delete: {
                                                        appId: item.appId,
                                                        name: item.name,
                                                        clientId: item.clientId
                                                    }
                                                })
                                            }
                                        ]
                                        : []}/>
                                <CardBody
                                    id={item.appId}
                                    title={item.name}
                                    text={item.description}
                                    style={{padding: "16px 24px 0"}}
                                    textStyles={{marginBottom: 0, maxHeight: "75px", lineHeight: "18px", fontSize: "12px", fontWeight: 400, overflow: "hidden"}}
                                    titleStyles={{fontWeight: 500, fontSize: "18px"}}
                                    onClick={() => this.props.history.push(`/${env}/apps/${item.appId}`)}
                                    popover={item.description && item.description.length > 255 ? true : false}
                                />
                                <CardCustomContent
                                    style={{
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                    }}
                                    onClick={() => this.props.history.push(`/${env}/apps/${item.appId}`)}
                                >
                                    <div
                                        style={{
                                            display: "inline-block",
                                            marginRight: 2,
                                            marginBottom: 2,
                                            width: 128,
                                        }}
                                    >
                                        <Chip
                                            label={(
                                                <>
                                                    <Avatar
                                                        style={{
                                                            display: 'inline-block',
                                                            width: '32px',
                                                            height: '32px',
                                                            left: '-11px',
                                                        }}
                                                    >
                                                        <Label
                                                            style={{
                                                                position: 'relative',
                                                                top: '3px',
                                                                left: '4px',
                                                                color: 'white',
                                                            }}
                                                        />
                                                    </Avatar>
                                                    {item.appProgram || "--"}
                                                </>
                                            )}
                                            classes={{ label: 'full-width-chip-label' }}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </CardCustomContent>
                            </CardWrapper>
                        )}
                    </CardsContainer>}
            </div>
            <br/>

            {["ready", "wait", "w-init"].includes(this.state.status) ? (
                <Dialogs
                    {...this.props}
                    state={this.state}
                    initialize={this.init}
                    onSetState={this.onSetState}
                />
            ) : null}
        </div>;
    }

    private onSetState = (state, cb) => this.setState(state, cb);

    private init = () => {
        this.setState({ status: 'init' })
    }
}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(Component));
