import * as validateId from "./validate-id";

import axiosWrapper from "./axiosWrapper";
import getAppAuthTypeFlags from "./getAppAuthTypeFlags";
import getHostnameInfo from "./getHostnameInfo";
import getLocationInfo from "./getLocationInfo";
import getLogoStringType from "./getLogoStringType";
import consoleLogger from "./consoleLogger";
import { getPath } from "./utils/";


// const debounce = require("debounce-promise")
const isUrl = require("is-url");

export function onWStep1(
    state: any,
    apps: any,
    config: any,
) {
    const name = state.data.name ? "" : "Required field";

    const ids = apps.map((item) => item.appId)
    let appId = "";
    appId = validateId.isUrlFriendly(state.data.appId) ? appId : "The ID can only contain: lower case letters of the English alphabet, numbers (0-9), hyphen/minus sign (-), it must start with a letter and end with a letter or number, not a hyphen";
    appId = validateId.isNotTooLong(state.data.appId) ? appId : "This ID is too long";
    appId = validateId.isNotTooShort(state.data.appId) ? appId : "This ID is too short";
    appId = state.data.appId ? appId : "Required field";
    appId = validateId.isUnique(state.data.appId, ids) ? appId : "This ID already exists";
    appId = validateId.isNotRestricted(state.data.appId, config.reservedIds) ? appId : "This ID is reserved";

    return {
        name,
        appId,
    };
}

export function onWStep2(state: any) {
    const { conf, bes, bulk } = getAppAuthTypeFlags(state.data.appAuthTypes);
    let clientSecret = "";
    let wellKnownJwksJson = bulk && !isUrl(state.data.wellKnownJwksJson) ? "This is not a valid URI" : "";
    if ((conf || bes) && !state.data.clientSecret) {
        clientSecret = "Required field";
    }

    return { clientSecret, wellKnownJwksJson };
}

export function onWStep3(state: any) {
    const scopes = state.data.scopes ? "" : "Required field";
    return { scopes };
}

export function onWStep4(state: any) {
    const { sof, bulk } = getAppAuthTypeFlags(state.data.appAuthTypes);
    let launchUrl = "";
    if (sof || bulk) {
        launchUrl = isUrl(state.data.launchUrl) ? launchUrl : "This is not a valid URI";
        launchUrl = state.data.launchUrl ? launchUrl : "Required field";
    }

    let healthUrl = state.data.healthUrl && !isUrl(state.data.healthUrl) ? "This is not a valid URI" : "";

    let newRedirectUrl = "";
    if ((!Array.isArray(state.data.redirectUrls) || !state.data.redirectUrls?.length) && !state.data.newRedirectUrl && sof) {
        newRedirectUrl = "Required field";
    }
    if (!!state.data.newRedirectUrl && !isUrl(state.data.newRedirectUrl)) {
        newRedirectUrl = "This is not a valid URI";
    }
    const newEhrListingUrl = !!state.data.newAppListing?.ehrListingUrl && !isUrl(state.data.newAppListing?.ehrListingUrl) && "This is not a valid URI";

    return {
        launchUrl,
        healthUrl,
        newRedirectUrl,
        newEhrListingUrl
    };
}
export function onEditDetails(state: any) {
    const { sof, conf, bes, bulk } = getAppAuthTypeFlags(state.data.appAuthTypes);
    const name = state.data.name ? "" : "Required field";

    let launchUrl = ""
    if (sof || bulk) {
        launchUrl = isUrl(state.data.launchUrl) ? launchUrl : "This is not a valid URI";
        launchUrl = state.data.launchUrl ? launchUrl : "Required field";
    }

    let healthUrl = state.data.healthUrl && !isUrl(state.data.healthUrl) ? "This is not a valid URI" : "";

    let newRedirectUrl = "";
    if ((!Array.isArray(state.data.redirectUrls) || !state.data.redirectUrls?.length) && !state.data.newRedirectUrl && sof) {
        newRedirectUrl = "Required field";
    }
    if (!!state.data.newRedirectUrl && !isUrl(state.data.newRedirectUrl)) {
        newRedirectUrl = "This is not a valid URI";
    }
    const newEhrListingUrl = !!state.data.newAppListing?.ehrListingUrl && !isUrl(state.data.newAppListing?.ehrListingUrl) && "This is not a valid URI";

    const scopes = state.data.scopes ? "" : "Required field";

    let clientSecret = "";
    if ((conf || bes) && !state.data.clientSecret) {
        clientSecret = "Required field";
    }
    let wellKnownJwksJson = bulk && !isUrl(state.data.wellKnownJwksJson) ? "This is not a valid URI" : "";

    return {
        name,
        launchUrl,
        healthUrl,
        scopes,
        clientSecret,
        newRedirectUrl,
        newEhrListingUrl,
        wellKnownJwksJson
    };
}

function _onSetClientId(props: any) {
    return new Promise((resolve) => {
        const clientId = getPath(props, "state.data.clientId");
        if (clientId) {
            const envApi = getPath(props, "config.envApi");
            const { accountId } = getHostnameInfo();
            const { env } = getLocationInfo(props.location, props.config);
            axiosWrapper(envApi, `api/${accountId}/env/${env}/apps/check-client-id-is-globally-unique/${clientId}`, "GET")
                .then(() => resolve(""))
                .catch((reason) => {
                    consoleLogger.log("::: reason", reason);
                    resolve("This client ID already exists");
                });
        }
        else {
            resolve("");
        }
    });
};

let appClientIdTimer;
export function onSetClientIdAsync(props: any) {
    clearTimeout(appClientIdTimer);
    return new Promise((resolve) => {
        appClientIdTimer = setTimeout(() => resolve(_onSetClientId(props)), 550);
    });
}

function _onEditLogoUrl(url, envApi) {
    return new Promise((resolve) => {
        if (url && getLogoStringType(url) !== ":FILE:") {
            if (!isUrl(url)) {
                resolve("This is not a valid URI");
            }
            else {
                if (url.includes('/environment-api/')) {
                    const logoUrl = url.split('/environment-api/')[1]
                    axiosWrapper(
                        envApi,
                        logoUrl,
                    ).then((res) => {
                        if (res.status === 200) {
                            resolve("")
                        } else {
                            resolve("Image doesn't exist");
                        }
                    }).catch(() => {
                        resolve("Image doesn't exist");
                    })
                } else {
                    const img = new Image();
                    img.onload = () => resolve("");
                    img.onerror = () => resolve("Image doesn't exist");
                    img.src = url;
                }
            }
        }
        else {
            resolve("");
        }
    });
};

let appLogoUrlTimer;
export function onEditLogoUrlAsync(props: any) {
    clearTimeout(appLogoUrlTimer);
    return new Promise((resolve) => {
        appLogoUrlTimer = setTimeout(() => resolve(_onEditLogoUrl(props.state.data.logoUrl, props.config.envApi)), 550);
    });
}

// -----------------------------------------------------------------------------
// export const logoUrl = debounce(
//     (url) => delay(500).then(() => _onLogoURI(url)),
//     1000,
//     { leading: true },
// );
