import * as React from "react";
import * as PropTypes from "prop-types";
import getAppsCallUrl from "../../../../../lib/getAppsCallUrl";
// import getActiveEnvironment from "../../../../../lib/getActiveEnvironment";
import {CardActions, Button} from "@material-ui/core";

// const activeEnv = getActiveEnvironment()
// const includeAuthV2 = [":LOCAL:", ":DEVELOPMENT:", ":DEV-AZURE:", ":TEST:", ":QA-AZURE:"].includes(activeEnv);

const Component: React.FunctionComponent<any> = props => {
    const canEdit = props.state.mode === ":EDIT:";
    const errorObj = props.apps?.selected?.error ?? null;
    const hasInvalidField = errorObj?.hasOwnProperty('invalidFields') && errorObj.invalidFields.length > 0;
    let disabled = !canEdit || !props.isValid || (props.state.status !== "ready" && !hasInvalidField);

    return <CardActions>
        <div style={{
            height: canEdit ? "36px" : 0,
            marginTop: canEdit ? "8px" : 0,
            marginBottom: canEdit ? "8px" : 0,
            textAlign: "right",
            transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)"
        }}>
            <Button variant="outlined" disabled={!canEdit} style={{display: canEdit ? "inline-block" : "none", marginRight: "8px"}} onClick={props.initialize}>
                Cancel
            </Button>
            <Button variant="contained" data-qa="app-details-edit-save-button" color="primary" disabled={disabled}
                style={{display: canEdit ? "inline-block" : "none"}}
                onClick={() => {
                    props.onSetState(
                        {status: "wait"},
                        async () => {
                            const accountId = props.state.data.accountId;
                            const appId = props.state.data.appId;
                            const environmentId = props.state.data.environmentId;

                            const data = {
                                accessTokenTTLSeconds: props.state.data.accessTokenTTLSeconds || 1800,
                                accountId,
                                activations: props.state.data.activations,
                                appAuthTypes: props.state.data.appAuthTypes,
                                appId,
                                clientId: props.state.data.clientId,
                                logViewer: props.state.data.logViewer,
                                clientSecret: props.state.data.clientSecret,
                                description: props.state.data.description,
                                environmentId,
                                launchUrl: props.state.data.launchUrl,
                                healthUrl: props.state.data.healthUrl,
                                logoUrl: props.state.data.logoUrl,
                                logoId: props.state.data.logoId,
                                name: props.state.data.name,
                                redirectUrls: props.state.data.redirectUrls,
                                appListings: props.state.data.appListings,
                                scopes: props.state.data.scopes,
                                clientAuthenticationMethod: props.state.data.clientAuthenticationMethod || null,
                                requireProofKey: props.state.data.requireProofKey || false,
                                wellKnownJwksJson: props.state.data.wellKnownJwksJson || null,
                            };

                            const appUrl = getAppsCallUrl(accountId, environmentId) + `/${appId}`;

                            const response = await props.appsUpdate(props.config, appUrl, data);
                            if (response && response.status < 300 && response.data) {
                                props.initialize();
                            }
                        }
                    );
                }}
            >
                Save
            </Button>
        </div>
    </CardActions>;
}

Component.propTypes = {
    apps: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,

    appsUpdate: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    onSetState: PropTypes.func.isRequired,

    isValid: PropTypes.bool
};

Component.defaultProps = {
    isValid: true
};

export default Component;
