import * as React from "react";
import * as PropTypes from "prop-types";
import getAppAuthTypeFlags from "../../../../lib/getAppAuthTypeFlags";
import {Checkbox, FormControlLabel, FormHelperText, Switch, TextField, FormControl} from "@material-ui/core";
import {RadioButtonUnchecked, RadioButtonChecked} from "@material-ui/icons";

const Component: React.FunctionComponent<any> = props => {
    let appAuthTypes = [...(props.state.data.appAuthTypes || [])];
    let clientAuthenticationMethod = props.state.data.clientAuthenticationMethod || 'NONE';
    let clientSecret = props.state.data.clientSecret || "";
    if (!props.noMasking && clientSecret.length && !props.state.secretReset) {
        clientSecret = "***********"
    }

    const {sof, pub, conf, bes, bulk} = getAppAuthTypeFlags(appAuthTypes);
    const requireProofKey = props.state.data.requireProofKey;
    const wellKnownJwksJson = props.state.data.wellKnownJwksJson;

    return props.canEdit ? renderEditMode() : renderViewMode();

    function renderEditMode() {
        return <div>
            <h4 style={{color: props.muiTheme.palette.textColor}}>
                Security
            </h4>

            <FormControlLabel label="Public SMART on FHIR" style={{marginLeft: "32px", marginBottom: "8px", width: "auto", display: "block"}}
                control={<Checkbox color="primary" checked={pub} checkedIcon={<RadioButtonChecked/>} icon={<RadioButtonUnchecked/>}
                    onChange={() => {
                        // if (!bes) {
                        appAuthTypes = ["SMART_ON_FHIR_PUBLIC"];
                        clientAuthenticationMethod = "NONE";
                        clientSecret = "";
                        props.onSetState({
                            secretReset: true,
                            data: {
                                ...props.state.data,
                                appAuthTypes,
                                clientAuthenticationMethod,
                                clientSecret,
                            }
                        });
                        // }
                    }}/>
                }/>

            <FormControlLabel label="Confidential SMART on FHIR" style={{marginLeft: "32px", marginBottom: "8px", width: "auto", display: "block"}}
                control={<Checkbox color="primary" data-qa="app-security-confidential-checkbox" checked={conf} checkedIcon={<RadioButtonChecked/>} icon={<RadioButtonUnchecked/>}
                    onChange={() => {
                        if (conf) {
                            appAuthTypes = ["SMART_ON_FHIR_CONFIDENTIAL"];
                            clientAuthenticationMethod = "CLIENT_SECRET_BASIC";
                            props.onSetState({
                                data: {
                                    ...props.state.data,
                                    appAuthTypes,
                                    clientAuthenticationMethod,
                                }
                            });
                        } else {
                            appAuthTypes = ["SMART_ON_FHIR_CONFIDENTIAL"];
                            clientAuthenticationMethod = "CLIENT_SECRET_BASIC";
                            clientSecret = "";
                            props.onSetState({
                                secretReset: true,
                                data: {
                                    ...props.state.data,
                                    appAuthTypes,
                                    clientAuthenticationMethod,
                                    clientSecret,
                                }
                            });
                        }
                    }}/>
                }/>

            <FormControlLabel label="OAuth2 Client Credentials" style={{marginLeft: "32px", marginBottom: "8px", width: "auto", display: "block"}}
                control={<Checkbox color="primary" data-qa="app-security-confidential-checkbox" checked={bes} checkedIcon={<RadioButtonChecked/>} icon={<RadioButtonUnchecked/>}
                    onChange={() => {
                        appAuthTypes = ["BACKEND_SERVICE"];
                        clientAuthenticationMethod = null;
                        clientSecret = "";
                        props.onSetState({
                            secretReset: true,
                            data: {
                                ...props.state.data,
                                appAuthTypes,
                                clientAuthenticationMethod,
                                clientSecret,
                                requireProofKey: false,
                            }
                        });
                    }}/>
                }/>

            <FormControlLabel label="Multi Patient Access" style={{marginLeft: "32px", marginBottom: "8px", width: "auto", display: "block"}}
                control={<Checkbox color="primary" data-qa="app-security-multipatient-checkbox" checked={bulk} checkedIcon={<RadioButtonChecked/>} icon={<RadioButtonUnchecked/>}
                    onChange={() => {
                        appAuthTypes = ["MULTI_PATIENT_ACCESS"];
                        clientAuthenticationMethod = "PRIVATE_KEY_JWT";
                        clientSecret = "";
                        props.onSetState({
                            secretReset: true,
                            data: {
                                ...props.state.data,
                                appAuthTypes,
                                clientAuthenticationMethod,
                                clientSecret,
                                requireProofKey: false,
                            }
                        });
                    }}/>
                }/>

            {(conf || bes) &&
            <TextField data-qa="app-security-client-secret" name="clientSecret" value={clientSecret || ""} label="Client Secret"
                error={!!props.state.dataValidation.clientSecret} helperText={props.state.dataValidation.clientSecret} fullWidth
                onClick={e => {
                    if ((e.target as any).value.localeCompare("***********") === 0) {
                        props.onSetState({
                            secretReset: true,
                            data: {
                                ...props.state.data,
                                clientSecret: ""
                            }
                        });
                    }
                }}
                onChange={e => {
                    props.onSetState({
                        data: {
                            ...props.state.data,
                            clientSecret: e.target.value
                        }
                    })
                }}
            />}

            {(bulk) ?
                <TextField data-qa="app-security-well-known-jwks-json" name="wellKnownJwksJson" value={wellKnownJwksJson || ""} label="JWKS URL"
                    error={!!props.state.dataValidation.wellKnownJwksJson} helperText={props.state.dataValidation.wellKnownJwksJson} fullWidth
                    onChange={e => {
                        props.onSetState({
                            data: {
                                ...props.state.data,
                                wellKnownJwksJson: e.target.value
                            }
                        })
                    }}
                /> : null}

            <FormControlLabel label="PKCE" style={{marginTop: "16px", marginBottom: "8px", width: "auto", display: "block"}}
                control={<Switch color="primary" checked={requireProofKey || false} disabled={!sof}
                    onChange={(event) => {
                        props.onSetState({
                            data: {
                                ...props.state.data,
                                requireProofKey: event.target.checked
                            }
                        });
                    }}/>}
            />
            <FormHelperText style={{marginTop: 0, marginBottom: "5px"}}>requires SMART ON FHIR type to be enabled.</FormHelperText>

            {renderAccessTokenTTL(props.canEdit)}
        </div>;
    }

    function renderViewMode() {
        let type = "";
        if (!sof && !pub && !conf && !bes) type = "NONE";
        else if (sof && pub && !conf && !bes) type = "SMART on FHIR Public App Launch";
        else if (sof && !pub && conf && !bes) type = "SMART on FHIR Confidential App Launch";
        else if (sof && !pub && conf && bes) type = "OAuth2 Client Credentials, SMART on FHIR Confidential App Launch";
        else if (!sof && !pub && !conf && bes) type = "OAuth2 Client Credentials";

        return <div>
            <h4 style={{marginBottom: 0, color: props.muiTheme.palette.textColor}}>
                Security
            </h4>

            <TextField name="appAuthType" value={type} label="App Auth Type" fullWidth disabled classes={{root: "disabled-text-field"}} style={{cursor: "default"}}/>
            {(conf || bes) &&
            <TextField name="clientSecret" value="***********" label="Client secret" error={props.state.dataValidation && !!props.state.dataValidation.clientSecret}
                helperText={props.state.dataValidation && props.state.dataValidation.clientSecret} fullWidth
                disabled classes={{root: "disabled-text-field"}} style={{cursor: "default"}}/>}
            <TextField name="requireProofKey" value={props.state.data.requireProofKey ? "Yes" : 'No'} label="PKCE" fullWidth
                disabled classes={{root: "disabled-text-field"}} style={{cursor: "default"}}/>
            <TextField name="multiPatientAccess" value={props.state.data.wellKnownJwksJson ? "Yes" : "No"} label="Multi Patient Access" fullWidth
                disabled classes={{root: "disabled-text-field"}} style={{cursor: "default"}}/>
            {props.state.data.wellKnownJwksJson ?<TextField name="wellKnownJwksJson" value={props.state.data.wellKnownJwksJson} label="JWKS URL" fullWidth
                disabled classes={{root: "disabled-text-field"}} style={{cursor: "default"}}/> : null}
            {renderAccessTokenTTL(false)}
        </div>;
    }

    function renderAccessTokenTTL(canEdit) {
        const textInputProps = {
            style: { maxWidth: "200px", marginTop: "26px" },
            type: "number",
            name: "accessTokenTTL",
            value: (props.state.data.accessTokenTTLSeconds / 60) || "",
            placeholder: canEdit ? "Type the access token timeout in minutes" : undefined,
            label: props.state.dataValidation.accessTokenTTL || "Access Token Timeout (minutes)",
            classes: { root: `${canEdit ? "" : " disabled-text-field"}` },
            onChange: (e) => {
                const valueInMinutes = Math.min(Number(e.target.value), 240);
                props.onSetState({
                    isNextWStepEnabled: false,
                    data: {
                        ...props.state.data,
                        accessTokenTTLSeconds: valueInMinutes * 60
                    }
                });
            },
            inputProps: {
                min: 0,
                max: 240,
                style: { width: "200px" } // Ensure textAlign is typed correctly
            },
            InputLabelProps: {
                style: { overflow: 'hidden', textOverflow: 'ellipsis', width: '250px' }
            },
            disabled: !canEdit,
            variant: "outlined" as "outlined"
        };

        return (
            <FormControl>
                <TextField {...textInputProps} />
                <FormHelperText>
                    Default value is 30 minutes. Maximum value is 240 minutes.
                </FormHelperText>
            </FormControl>
        );
    }
}

Component.propTypes = {
    muiTheme: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    onSetState: PropTypes.func.isRequired,
    canEdit: PropTypes.bool
}

Component.defaultProps = {
    canEdit: false
};

export default Component;
