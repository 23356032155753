import * as React from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Radio from '@material-ui/core/Radio'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Alert from '@material-ui/lab/Alert'
import CopyIcon from '@material-ui/icons/FileCopy'
import InfoIcon from '@material-ui/icons/Info'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import axiosWrapper from '../../../../../../../../../lib/axiosWrapper'
import {getPath} from "../../../../../../../../../lib/utils/"
import consoleLogger from "../../../../../../../../../lib/consoleLogger"
import PracticeList from '../../../../PracticeList'
import type {
    TAppActivation,
    TClientAuthMethod,
    TGateway,
    TActivationSecurityOption
} from '../../../../../../../../../types'
import type {
    TAuthMode,
    TDataValidation,
    TEhrAuthType,
    TProxyAuthType,
    TStatus as TEStatus,
    TSystemAuthType,
} from '../../..'

type TProps = {
    appActivation: TAppActivation
    authMode: TAuthMode
    canEdit: boolean
    config: any
    dataValidation: TDataValidation
    disableAuthMode: boolean
    ehrAuthType: TEhrAuthType
    estatus: TEStatus
    gateways: TGateway[]
    isAllscriptsUnityGateway: boolean
    isAppLaunchingWithinIKMG2: boolean
    isCernerR4Gateway: boolean
    isEpicR4Gateway: boolean
    isEpicR4ExternalGateway: boolean
    isExtAuthDa: boolean
    isExtAuthGtw: boolean
    isIKnowMedExtGateway: boolean
    isLaunchProxyRequired: boolean
    isVarianR4Gateway: boolean
    muiTheme: any
    proxyAuthType: TProxyAuthType
    systemAuthType: TSystemAuthType
    ui: any
    setAppActivation: React.Dispatch<React.SetStateAction<TAppActivation>>
    setAuthMode: React.Dispatch<React.SetStateAction<TAuthMode>>
    setEhrAuthType: React.Dispatch<React.SetStateAction<TEhrAuthType>>
    setEStatus: React.Dispatch<React.SetStateAction<TEStatus>>
    setIsAppLaunchingWithinIKMG2: React.Dispatch<React.SetStateAction<boolean>>
    setProxyAuthType: React.Dispatch<React.SetStateAction<TProxyAuthType>>
    setSystemAuthType: React.Dispatch<React.SetStateAction<TSystemAuthType>>
}

const EStep1: React.FC<TProps> = (props: TProps) => {
    const appRegUrlRef = React.useRef<any>(null)

    const [isAlpServicePasswordVisible, setAlpServicePasswordVisible] = React.useState(false)
    const [isAlpTestAppUserPasswordVisible, setAlpTestAppUserPasswordVisible] = React.useState(false)
    const [isExternalAuthClientSecretVisible, setExternalAuthClientSecretVisible] = React.useState(false)
    const [isSmart2ProxyClientSecretVisible, setSmart2ProxyClientSecretVisible] = React.useState(false)
    const [isSlpClientSecretVisible, setSlpClientSecretVisible] = React.useState(false)

    let appRegUrl = ''
    if (props.isEpicR4Gateway) {
        appRegUrl = 'https://appmarket.epic.com/interconnect-amcurprd-oauth'
    }
    else if (props.isCernerR4Gateway) {
        appRegUrl = 'https://cernercentral.com/system-accounts/'
    }

    return (
        <div style={{ padding: '24px' }}>
            {/* START common settings -------------------------------------- */}
            <TextField
                data-e2e="activation_name_wrapper"
                name="activation-name"
                value={props.appActivation.name ?? ''}
                disabled={!props.canEdit || props.estatus !== ':READY:'}
                label="Activation Name"
                error={!!props.dataValidation.appActivation?.name}
                helperText={props.dataValidation.appActivation?.name ?? ''}
                fullWidth
                style={{ marginTop: '16px' }}
                onChange={(event) => {
                    props.setAppActivation((prevState) => ({
                        ...prevState,
                        name: event.target.value,
                    }))
                }}
            />

            <FormControl
                disabled={!props.canEdit || props.estatus !== ':READY:'}
                error={!!props.dataValidation.appActivation?.gatewayId}
                fullWidth
                style={{ marginTop: '16px' }}
            >
                <InputLabel>
                    Select a Gateway
                </InputLabel>
                <Select
                    data-e2e="act_gateway_select"
                    value={props.appActivation.gatewayId ?? ''}
                    onChange={(event) => {
                        props.setAppActivation((prevState) => ({
                            ...prevState,
                            gatewayId: event.target.value as string,
                        }))
                    }}
                >
                    {props.gateways.map((gw) => {
                        const isSelected = gw.gatewayId === props.appActivation.gatewayId
                        return (
                            <MenuItem
                                data-e2e={`act_gateway_item_${gw.gatewayId}`}
                                key={gw.gatewayId}
                                value={gw.gatewayId}
                            >
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                                    <div style={{ fontWeight: isSelected ? 800 : 400 }}>
                                        {gw.name}&nbsp;
                                    </div>
                                    {gw.requiresLaunchProxy
                                        ? (
                                            <Chip
                                                style={{
                                                    height: '20px',
                                                    backgroundColor: props.ui.xtheme.palette.colorBlueLight,
                                                }}
                                                label={(
                                                    <span>
                                                        Launch Proxy Required
                                                    </span>
                                                )}
                                            />
                                        ) : null}
                                </div>
                            </MenuItem>
                        )
                    })}
                </Select>
                {props.dataValidation.appActivation?.gatewayId ? (
                    <FormHelperText>
                        {props.dataValidation.appActivation?.gatewayId ?? ''}
                    </FormHelperText>
                ) : null}
            </FormControl>

            <TextField
                name="interopio-context"
                value={props.appActivation.interopioContext ?? ''}
                disabled={!props.canEdit || props.estatus !== ':READY:'}
                label="Context"
                placeholder="Valid JSON"
                error={!!props.dataValidation.appActivation.interopioContext}
                helperText={props.dataValidation.appActivation.interopioContext}
                fullWidth
                multiline
                style={{ marginTop: '16px' }}
                onChange={(event) => {
                    props.setAppActivation((prevState) => ({
                        ...prevState,
                        interopioContext: event.target.value || null,
                    }))
                }}
            />
            {/* END common settings ---------------------------------------- */}

            {/* START allscripts unity type -------------------------------- */}
            {props.isAllscriptsUnityGateway ? (
                <TextField
                    name="alp-app-name"
                    label="Allscripts App Name"
                    value={props.appActivation.allscriptsUnityLaunchProxyInfoApi?.appName ?? ''}
                    disabled={!props.canEdit || props.estatus !== ':READY:'}
                    error={!!props.dataValidation.appActivation?.allscriptsUnityAppName}
                    helperText={props.dataValidation.appActivation?.allscriptsUnityAppName ?? ''}
                    fullWidth
                    style={{ marginTop: '16px' }}
                    onChange={(event) => {
                        props.setAppActivation((prevState) => ({
                            ...prevState,
                            allscriptsUnityLaunchProxyInfoApi: {
                                ...(prevState.allscriptsUnityLaunchProxyInfoApi || {}),
                                appName: event.target.value,
                            },
                        }))
                    }}
                />
            ) : null}

            {props.isAllscriptsUnityGateway ? (
                <TextField
                    name="alp-test-app-user"
                    label="Test App User"
                    value={props.appActivation.allscriptsUnityLaunchProxyInfoApi?.testAppUser ?? ''}
                    disabled={!props.canEdit || props.estatus !== ':READY:'}
                    fullWidth
                    style={{ marginTop: '16px' }}
                    onChange={(event) => {
                        props.setAppActivation((prevState) => ({
                            ...prevState,
                            allscriptsUnityLaunchProxyInfoApi: {
                                ...(prevState.allscriptsUnityLaunchProxyInfoApi || {}),
                                testAppUser: event.target.value,
                            },
                        }))
                    }}
                />
            ) : null}

            {props.isAllscriptsUnityGateway ? (
                <FormControl fullWidth style={{ marginTop: '16px' }}>
                    <InputLabel>
                        Test App User Password
                    </InputLabel>
                    <Input
                        name="alp-test-app-user-password"
                        value={props.appActivation.allscriptsUnityLaunchProxyInfoApi?.testAppUserPassword ?? ''}
                        disabled={!props.canEdit || props.estatus !== ':READY:'}
                        type={isAlpTestAppUserPasswordVisible ? 'text' : 'password'}
                        endAdornment={props.appActivation.allscriptsUnityLaunchProxyInfoApi?.testAppUserPassword ? (
                            <InputAdornment position="end">
                                <IconButton
                                    edge="end"
                                    onClick={() => {
                                        setAlpTestAppUserPasswordVisible((prevState) => !prevState)
                                    }}
                                >
                                    {isAlpTestAppUserPasswordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                        ) : null}
                        onChange={(event) => {
                            props.setAppActivation((prevState) => ({
                                ...prevState,
                                allscriptsUnityLaunchProxyInfoApi: {
                                    ...(prevState.allscriptsUnityLaunchProxyInfoApi || {}),
                                    testAppUserPassword: event.target.value,
                                },
                            }))
                        }}
                    />
                </FormControl>
            ) : null}

            {props.isAllscriptsUnityGateway ? (
                <TextField
                    name="alp-service-user"
                    label="Service User"
                    value={props.appActivation.allscriptsUnityLaunchProxyInfoApi?.serviceUser ?? ''}
                    disabled={!props.canEdit || props.estatus !== ':READY:'}
                    fullWidth
                    style={{ marginTop: '16px' }}
                    onChange={(event) => {
                        props.setAppActivation((prevState) => ({
                            ...prevState,
                            allscriptsUnityLaunchProxyInfoApi: {
                                ...(prevState.allscriptsUnityLaunchProxyInfoApi || {}),
                                serviceUser: event.target.value,
                            },
                        }))
                    }}
                />
            ) : null}

            {props.isAllscriptsUnityGateway ? (
                <FormControl fullWidth style={{ marginTop: '16px' }}>
                    <InputLabel>
                        Service Password
                    </InputLabel>
                    <Input
                        name="alp-service-password"
                        value={props.appActivation.allscriptsUnityLaunchProxyInfoApi?.servicePassword ?? ''}
                        disabled={!props.canEdit || props.estatus !== ':READY:'}
                        type={isAlpServicePasswordVisible ? 'text' : 'password'}
                        endAdornment={props.appActivation.allscriptsUnityLaunchProxyInfoApi?.servicePassword ? (
                            <InputAdornment position="end">
                                <IconButton
                                    edge="end"
                                    onClick={() => {
                                        setAlpServicePasswordVisible((prevState) => !prevState)
                                    }}
                                >
                                    {isAlpServicePasswordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                        ) : null}
                        onChange={(event) => {
                            props.setAppActivation((prevState) => ({
                                ...prevState,
                                allscriptsUnityLaunchProxyInfoApi: {
                                    ...(prevState.allscriptsUnityLaunchProxyInfoApi || {}),
                                    servicePassword: event.target.value,
                                },
                            }))
                        }}
                    />
                </FormControl>
            ) : null}
            {/* END allscripts unity type ---------------------------------- */}

            {/* START auth model ------------------------------------------- */}
            {props.isEpicR4Gateway || props.isVarianR4Gateway ? (
                <FormControl
                    data-e2e='auth-model'
                    fullWidth
                    disabled={!props.canEdit || props.estatus !== ':READY:' || props.disableAuthMode}
                    style={{ marginTop: '16px' }}
                >
                    <InputLabel>
                        Authorization Model
                    </InputLabel>
                    <Select
                        value={props.authMode}
                        onChange={(event) => {
                            props.setAuthMode(event.target.value as TAuthMode)
                        }}
                    >
                        <MenuItem value=":SMART_1:">
                            SMART 1.0
                        </MenuItem>
                        <MenuItem value=":SMART_2:">
                            SMART 2.0
                        </MenuItem>
                    </Select>
                    <FormHelperText>{props.disableAuthMode ? "One activatiion of each authorization model type allowed per gateway" : ""}</FormHelperText>
                </FormControl>
            ) : null}
            {/* END auth model --------------------------------------------- */}

            {/* START EHR auth type ---------------------------------------- */}
            {props.isLaunchProxyRequired && !props.isAllscriptsUnityGateway ? (
                <FormControl
                    disabled={!props.canEdit || props.estatus !== ':READY:'}
                    fullWidth
                    style={{ marginTop: '16px' }}
                >
                    <InputLabel>
                        EHR Authentication Type
                    </InputLabel>
                    <Select
                        data-e2e="act_ehr_auth_type"
                        value={props.ehrAuthType}
                        onChange={(event) => {
                            props.setEhrAuthType(event.target.value as TEhrAuthType)
                        }}
                    >
                        <MenuItem
                            data-e2e="act_ehr_auth_type_item_proxy"
                            value=":PROXY:"
                        >
                            Proxy
                        </MenuItem>
                        {!props.isExtAuthDa && !props.isExtAuthGtw && (props.isCernerR4Gateway || props.isEpicR4Gateway) ? (
                            <MenuItem
                                data-e2e="act_ehr_auth_type_item_system"
                                value=":SYSTEM:"
                            >
                                System
                            </MenuItem>
                        ) : null}
                    </Select>
                </FormControl>
            ) : null}

            {(props.isExtAuthDa) ? (
                <Alert severity="info">
                    Backend/External auth is already enabled at the data adapter level for this gateway
                </Alert>
            ) : null}

            {(props.isExtAuthGtw) ? (
                <Alert severity="info">
                    Backend/External auth is already enabled at for a different activation on this gateway
                </Alert>
            ) : null}
            {/* END EHR auth type ------------------------------------------ */}

            {/* START proxy auth type -------------------------------------- */}
            {props.isLaunchProxyRequired
                && !props.isAllscriptsUnityGateway
                && props.ehrAuthType === ':PROXY:' ? (
                    <FormGroup style={{ marginTop: '16px' }}>
                        <FormControlLabel
                            data-e2e="act_ehr_auth_type_proxy_item_public"
                            label="Public Client"
                            control={(
                                <Radio
                                    disabled={!props.canEdit || props.estatus !== ':READY:'}
                                    checked={props.proxyAuthType === ':PUBLIC:'}
                                    color="primary"
                                    onChange={() => {
                                        props.setProxyAuthType(':PUBLIC:')
                                    }}
                                />
                            )}
                        />
                        <FormControlLabel
                            data-e2e="act_ehr_auth_type_proxy_item_confidential"
                            label="Confidential Client"
                            control={(
                                <Radio
                                    disabled={!props.canEdit || props.estatus !== ':READY:'}
                                    checked={props.proxyAuthType === ':CONFIDENTIAL:'}
                                    color="primary"
                                    onChange={() => {
                                        props.setProxyAuthType(':CONFIDENTIAL:')
                                    }}
                                />
                            )}
                        />
                    </FormGroup>
                ) : null}

            {props.authMode === ':SMART_2:'
                && props.proxyAuthType === ':CONFIDENTIAL:' ? (
                    <FormControl
                        fullWidth
                        disabled={!props.canEdit || props.estatus !== ':READY:'}
                        error={!!props.dataValidation.appActivation?.clientAuthType}
                        style={{ marginTop: '16px' }}
                    >
                        <InputLabel>
                            Client Authentication Type
                        </InputLabel>
                        <Select
                            data-e2e='client-auth-type'
                            value={props.appActivation.smartLaunchProxyInfo2Api?.clientAuthenticationMethod}
                            onChange={(event) => {
                                props.setAppActivation((prevState) => ({
                                    ...prevState,
                                    smartLaunchProxyInfo2Api: {
                                        ...(prevState.smartLaunchProxyInfo2Api || {}),
                                        clientAuthenticationMethod: event.target.value as TClientAuthMethod,
                                    },
                                }))
                            }}
                        >
                            <MenuItem value="SYMMETRIC">
                                Symmetric
                            </MenuItem>
                            <MenuItem value="ASYMMETRIC">
                                Asymmetric
                            </MenuItem>
                        </Select>
                    </FormControl>
                ) : null}

            {props.authMode !== ':SMART_2:'
                && props.isLaunchProxyRequired
                && !props.isAllscriptsUnityGateway
                && props.ehrAuthType === ':PROXY:' ? (
                    <TextField
                        name="slp-scopes"
                        label="Scopes"
                        value={props.appActivation.smartLaunchProxyInfo?.scopes ?? ''}
                        disabled={!props.canEdit || props.estatus !== ':READY:'}
                        fullWidth
                        multiline
                        style={{ marginTop: '16px' }}
                        onChange={(event) => {
                            props.setAppActivation((prevState) => ({
                                ...prevState,
                                smartLaunchProxyInfo: {
                                    ...(prevState.smartLaunchProxyInfo || {}),
                                    scopes: event.target.value,
                                },
                            }))
                        }}
                    />
                ) : null}

            {props.authMode !== ':SMART_2:'
                && props.isLaunchProxyRequired
                && !props.isAllscriptsUnityGateway
                && props.ehrAuthType === ':PROXY:' ? (
                    <TextField
                        name="slp-client-id"
                        value={props.appActivation.smartLaunchProxyInfo?.clientId ?? ''}
                        disabled={!props.canEdit || props.estatus !== ':READY:'}
                        error={!!props.dataValidation.appActivation.clientId}
                        label={props.dataValidation.appActivation.clientId || `Client ID ${!props.appActivation.smartLaunchProxyInfo?.clientId ? '(leave blank to auto generate)' : ''}`}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton edge="end">
                                        <Tooltip title="This client ID is issued by the EHR. Register this client ID on EHR proxying app launch.">
                                            <InfoIcon />
                                        </Tooltip>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        fullWidth
                        style={{ marginTop: '16px' }}
                        onChange={(event) => {
                            props.setAppActivation((prevState) => ({
                                ...prevState,
                                smartLaunchProxyInfo: {
                                    ...(prevState.smartLaunchProxyInfo || {}),
                                    clientId: event.target.value,
                                },
                            }))
                        }}
                    />
                ) : null}
            {/* END proxy auth type ---------------------------------------- */}

            {/* START proxy/confidential client auth type ------------------ */}
            {props.authMode !== ':SMART_2:'
                && props.isLaunchProxyRequired
                && !props.isAllscriptsUnityGateway
                && props.ehrAuthType === ':PROXY:'
                && props.proxyAuthType === ':CONFIDENTIAL:' ? (
                    <TextField
                        name="slp-client-secret"
                        label="Client Secret"
                        value={props.appActivation.smartLaunchProxyInfo?.clientSecret ?? ''}
                        disabled={!props.canEdit || props.estatus !== ':READY:'}
                        type={isSlpClientSecretVisible ? 'text' : 'password'}
                        error={!!props.dataValidation.appActivation.clientSecret}
                        fullWidth
                        helperText={props.dataValidation.appActivation.clientSecret}
                        style={{ marginTop: '16px' }}
                        onClick={props.canEdit ? (e => {
                            let targetValue = (e.target as any).value;
                            if (targetValue?.localeCompare("<hidden>") === 0) {
                                props.setAppActivation((prevState) => ({
                                    ...prevState,
                                    smartLaunchProxyInfo: {
                                        ...(prevState.smartLaunchProxyInfo || {}),
                                        clientSecret: '',
                                    },
                                }));
                            }
                        }) : null}
                        onChange={(event) => {
                            props.setAppActivation((prevState) => ({
                                ...prevState,
                                smartLaunchProxyInfo: {
                                    ...(prevState.smartLaunchProxyInfo || {}),
                                    clientSecret: event.target.value,
                                },
                            }))
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" >
                                    <IconButton
                                        disabled={!props.canEdit || props.estatus !== ':READY:'}
                                        aria-label="toggle secret visibility"
                                        onClick={() => {
                                            if (props.appActivation.smartLaunchProxyInfo?.clientSecret?.localeCompare("<hidden>") === 0) {
                                                props.setAppActivation((prevState) => ({
                                                    ...prevState,
                                                    smartLaunchProxyInfo: {
                                                        ...(prevState.smartLaunchProxyInfo || {}),
                                                        clientSecret: '',
                                                    },
                                                }));
                                            }
                                            setSlpClientSecretVisible(!isSlpClientSecretVisible)
                                        }}
                                        onMouseDown={(event) => event.preventDefault()}
                                    >
                                        {isSlpClientSecretVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                ) : null}
            {/* END proxy/confidential client auth type -------------------- */}

            {/* START system auth type ------------------------------------- */}
            {(props.isCernerR4Gateway || props.isEpicR4Gateway)
                && !props.isExtAuthDa
                && !props.isAllscriptsUnityGateway
                && props.ehrAuthType === ':SYSTEM:' ? (
                    <FormGroup style={{ marginTop: '16px' }}>
                        <FormControlLabel
                            data-e2e="act_ehr_auth_type_system_item_backend"
                            label="Backend Auth"
                            control={(
                                <Radio
                                    disabled={!props.canEdit || props.estatus !== ':READY:'}
                                    checked={props.systemAuthType === ':BACKEND:'}
                                    color="primary"
                                    onChange={() => {
                                        props.setSystemAuthType(':BACKEND:')
                                    }}
                                />
                            )}
                        />
                        <FormControlLabel
                            data-e2e="act_ehr_auth_type_system_item_external"
                            label="External Auth"
                            control={(
                                <Radio
                                    disabled={
                                        !props.canEdit ||
                                        props.estatus !== ':READY:'}
                                    checked={props.systemAuthType === ':EXTERNAL:'}
                                    color="primary"
                                    onChange={() => {
                                        props.setSystemAuthType(':EXTERNAL:')
                                    }}
                                />
                            )}
                            style={(props.isEpicR4Gateway ||
                                props.isEpicR4ExternalGateway) ? {display: 'none'} : {}}
                        />
                    </FormGroup>
                ) : null}

            {(
                props.authMode !== ':SMART_2:'
                && (props.isCernerR4Gateway || props.isEpicR4Gateway)
                && !props.isAllscriptsUnityGateway
                && props.ehrAuthType === ':SYSTEM:'
            ) || (
                props.authMode === ':SMART_2:'
                && props.appActivation.smartLaunchProxyInfo2Api?.clientAuthenticationMethod === 'ASYMMETRIC'
            ) ? (
                    <TextField
                        name="external-auth-app-reg-url"
                        value={appRegUrl}
                        disabled
                        label="Backend Auth App Registration Link"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        onClick={() => {
                                            appRegUrlRef.current.select()
                                            document.execCommand('copy')
                                        }}
                                    >
                                        <Tooltip title="Copy"><CopyIcon /></Tooltip>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        fullWidth
                        style={{ marginTop: '16px' }}
                    />
                ) : null}

            {props.authMode !== ':SMART_2:'
                && (props.isCernerR4Gateway || props.isEpicR4Gateway)
                && !props.isAllscriptsUnityGateway
                && props.ehrAuthType === ':SYSTEM:' ? (
                    <TextField
                        name="external-auth-client-id"
                        value={props.appActivation.externalAuth?.clientId ?? ''}
                        disabled={!props.canEdit || props.estatus !== ':READY:'}
                        error={!!props.dataValidation.appActivation.clientId}
                        label="Client ID"
                        fullWidth
                        style={{ marginTop: '16px' }}
                        onChange={(event) => {
                            props.setAppActivation((prevState) => ({
                                ...prevState,
                                externalAuth: {
                                    ...(prevState.externalAuth || {}),
                                    clientId: event.target.value,
                                },
                            }))
                        }}
                    />
                ) : null}

            {props.authMode !== ':SMART_2:'
                && props.isEpicR4Gateway
                && !props.isAllscriptsUnityGateway
                && props.ehrAuthType === ':SYSTEM:' ? (
                    <TextField
                        name="external-auth-epic-app-name"
                        label="Epic App Name"
                        value={props.appActivation.externalAuth?.epicAppName ?? ''}
                        disabled={!props.canEdit || props.estatus !== ':READY:'}
                        fullWidth
                        style={{ marginTop: '16px' }}
                        onChange={(event) => {
                            props.setAppActivation((prevState) => ({
                                ...prevState,
                                externalAuth: {
                                    ...(prevState.externalAuth || {}),
                                    epicAppName: event.target.value,
                                },
                            }))
                        }}
                    />
                ) : null}

            {props.authMode !== ':SMART_2:'
                && props.isEpicR4Gateway
                && !props.isAllscriptsUnityGateway
                && props.ehrAuthType === ':SYSTEM:' ? (
                    <Button
                        data-e2e="act_generate_key_pair"
                        disabled={(
                            !props.canEdit
                            || props.estatus !== ':READY:'
                            || !props.appActivation.externalAuth?.epicAppName
                        )}
                        variant="contained"
                        color="primary"
                        style={{ display: 'block', marginTop: '16px', marginBottom: '16px' }}
                        onClick={async () => {
                            props.setEStatus(':WAITING:')
                            const url = `api/encryption`
                            const putDataObj = {
                                accountId: props.appActivation.externalAuth?.accountId,
                                appId: props.appActivation.externalAuth?.appId,
                                clientId: props.appActivation.externalAuth?.clientId,
                                dataSourceId: props.appActivation.externalAuth?.dataSourceId,
                                environmentId: props.appActivation.externalAuth?.environmentId,
                                gatewayId: props.appActivation.externalAuth?.gatewayId,
                            }
                            let res: {
                            data: TAppActivation['externalAuth']
                        }
                            try {
                                res = await axiosWrapper(props.config.envApi, url, 'PUT', putDataObj)
                            }
                            catch(reason) {
                                res = {
                                    data: {
                                        externalAuthApiId: 'ERROR_externalAuthApiId_' + Date.now(),
                                        epicPublicKey: 'ERROR_epicPublicKey_' + reason.toString(),
                                    },
                                }
                            }
                            props.setAppActivation((prevState) => ({
                                ...prevState,
                                externalAuth: {
                                    ...(prevState.externalAuth || {}),
                                    externalAuthApiId: res.data.externalAuthApiId,
                                    epicPublicKey: res.data.epicPublicKey,
                                },
                            }))
                            props.setEStatus(':READY:')
                        }}
                    >
                        Generate public/private key pair
                    </Button>
                ) : null}

            {props.authMode !== ':SMART_2:'
                && props.isEpicR4Gateway
                && !props.isAllscriptsUnityGateway
                && props.ehrAuthType === ':SYSTEM:' ? (
                    <Button
                        data-e2e="act_download_pem"
                        disabled={
                            !props.canEdit
                            || props.estatus !== ':READY:'
                            || !props.appActivation.externalAuth?.externalAuthApiId
                            || !props.appActivation.externalAuth?.epicAppName
                            || !props.appActivation.externalAuth?.epicPublicKey
                        }
                        variant="contained"
                        color="primary"
                        style={{ display: 'block', marginTop: '16px', marginBottom: '16px' }}
                        onClick={async () => {
                            props.setEStatus(':WAITING:')
                            try {
                                const url = `api/encryption/${props.appActivation.externalAuth?.externalAuthApiId}/${props.appActivation.externalAuth?.epicAppName}`
                                const res: { data?: string } = await axiosWrapper(props.config.envApi, url, 'GET', null, {}, 'stream')
                                if (typeof res.data === 'string') {
                                    const element = document.createElement('a')
                                    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(res.data))
                                    element.setAttribute('download', 'pem')
                                    element.style.display = 'none'
                                    document.body.appendChild(element)
                                    element.click()
                                    document.body.removeChild(element)
                                }
                                else {
                                    throw new Error('Error: Could not find PEM string! "data" field is missing in the response or it is not of type "string"!')
                                }
                            }
                            catch (reason) {
                                consoleLogger.error(reason)
                            }
                            props.setEStatus(':READY:')
                        }}
                    >
                        Download PEM file
                    </Button>
                ) : null}

            {props.authMode !== ':SMART_2:'
                && props.isEpicR4Gateway
                && !props.isAllscriptsUnityGateway
                && props.ehrAuthType === ':SYSTEM:' ? (
                    <div>
                        <input id="upload_pem_button" type="file" accept='text/plain' style={{display: "none"}}
                            onChange={async event => {
                                props.setEStatus(':WAITING:')
                                consoleLogger.log(event)
                                let file: File = getPath(event, "target.files.0");
                                if (!file) {
                                    return;
                                }

                                let bodyFormData = new FormData();
                                bodyFormData.append("externalAuthApi", new Blob([JSON.stringify({
                                    accountId: props.appActivation.externalAuth?.accountId,
                                    epicAppName: props.appActivation.externalAuth?.epicAppName,
                                    authType: "BACKEND",
                                    appId: props.appActivation.externalAuth?.appId,
                                    clientId: props.appActivation.externalAuth?.clientId,
                                    dataSourceId: props.appActivation.externalAuth?.dataSourceId,
                                    environmentId: props.appActivation.externalAuth?.environmentId,
                                    gatewayId: props.appActivation.externalAuth?.gatewayId,
                                })], {type: "application/json"}));
                                bodyFormData.append("pemFile", file);

                                const url = `api/encryption/upload`

                                let res: {
                                    data: TAppActivation['externalAuth']
                                }
                                try {
                                    res = await axiosWrapper(props.config.envApi, url, 'PUT', bodyFormData)
                                }
                                catch(reason) {
                                    res = {
                                        data: {
                                            externalAuthApiId: 'ERROR_externalAuthApiId_' + Date.now(),
                                            epicPublicKey: 'ERROR_epicPublicKey_' + reason.toString(),
                                        },
                                    }
                                }
                                props.setAppActivation((prevState) => ({
                                    ...prevState,
                                    externalAuth: {
                                        ...(prevState.externalAuth || {}),
                                        externalAuthApiId: res.data.externalAuthApiId,
                                        epicPublicKey: res.data.epicPublicKey,
                                    },
                                }))
                                props.setEStatus(':READY:')
                            }}
                        />
                        <label htmlFor="upload_pem_button">
                            <Button
                                data-e2e="act_upload_pem"
                                disabled={(
                                    !props.canEdit
                                    || props.estatus !== ':READY:'
                                    || !props.appActivation.externalAuth?.epicAppName
                                )}
                                variant="contained"
                                color="primary"
                                component="span"
                            >
                                Upload PEM file
                            </Button>
                        </label>
                    </div>
                ) : null}

            {props.authMode !== ':SMART_2:'
                && (props.isCernerR4Gateway)
                && !props.isAllscriptsUnityGateway
                && props.ehrAuthType === ':SYSTEM:' ? (
                    <FormControl fullWidth style={{ marginTop: '16px' }}>
                        <InputLabel>
                            Client Secret
                        </InputLabel>
                        <Input
                            name="external-auth-client-secret"
                            value={props.appActivation.externalAuth?.clientSecret ?? ''}
                            disabled={!props.canEdit || props.estatus !== ':READY:'}
                            type={isExternalAuthClientSecretVisible ? 'text' : 'password'}
                            onClick={props.canEdit ? (e => {
                                let targetValue = (e.target as any).value;
                                if (targetValue?.localeCompare("<hidden>") === 0) {
                                    props.setAppActivation((prevState) => ({
                                        ...prevState,
                                        externalAuth: {
                                            ...(prevState.externalAuth || {}),
                                            clientSecret: "",
                                        },
                                    }));
                                }
                            }) : null}
                            onChange={(event) => {
                                props.setAppActivation((prevState) => ({
                                    ...prevState,
                                    externalAuth: {
                                        ...(prevState.externalAuth || {}),
                                        clientSecret: event.target.value,
                                    },
                                }))
                            }}
                            endAdornment={
                                <InputAdornment position="end" >
                                    <IconButton
                                        disabled={!props.canEdit || props.estatus !== ':READY:'}
                                        aria-label="toggle secret visibility"
                                        onClick={() => {
                                            if (props.appActivation.externalAuth?.clientSecret?.localeCompare("<hidden>") === 0) {
                                                props.setAppActivation((prevState) => ({
                                                    ...prevState,
                                                    externalAuth: {
                                                        ...(prevState.externalAuth || {}),
                                                        clientSecret: "",
                                                    },
                                                }));
                                            }
                                            setExternalAuthClientSecretVisible(!isExternalAuthClientSecretVisible)
                                        }}
                                        onMouseDown={(event) => event.preventDefault()}
                                    >
                                        {isExternalAuthClientSecretVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                ) : null}

            {props.authMode !== ':SMART_2:'
                && (props.isCernerR4Gateway || props.isEpicR4Gateway)
                && !props.isAllscriptsUnityGateway
                && props.ehrAuthType === ':SYSTEM:' ? (
                    <TextField
                        name="external-auth-token-url"
                        label="Token URL"
                        value={props.appActivation.externalAuth?.tokenEndpoint ?? ''}
                        disabled={!props.canEdit || props.estatus !== ':READY:'}
                        fullWidth
                        style={{ marginTop: '16px' }}
                        onChange={(event) => {
                            props.setAppActivation((prevState) => ({
                                ...prevState,
                                externalAuth: {
                                    ...(prevState.externalAuth || {}),
                                    tokenEndpoint: event.target.value,
                                },
                            }))
                        }}
                    />
                ) : null}
            {/* END system auth type --------------------------------------- */}

            {/* START more SMART 2 settings --------------------------------- */}
            {props.authMode === ':SMART_2:' ? (
                <TextField
                    name="smart2-client-id"
                    value={props.appActivation.smartLaunchProxyInfo2Api?.clientId ?? ''}
                    disabled={!props.canEdit || props.estatus !== ':READY:'}
                    error={!!props.dataValidation.appActivation.clientId}
                    label={props.dataValidation.appActivation.clientId || `Client ID`}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton edge="end">
                                    <Tooltip title="This client ID is issued by the EHR. Register this client ID on EHR proxying app launch.">
                                        <InfoIcon />
                                    </Tooltip>
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    fullWidth
                    style={{ marginTop: '16px' }}
                    onChange={(event) => {
                        props.setAppActivation((prevState) => ({
                            ...prevState,
                            smartLaunchProxyInfo2Api: {
                                ...(prevState.smartLaunchProxyInfo2Api || {}),
                                clientId: event.target.value,
                            },
                        }))
                    }}
                />
            ) : null}
            {/* END more SMART 2 settings ---------------------------------- */}

            {/* START more SMART 2 / confidential settings ----------------- */}
            {props.authMode === ':SMART_2:'
                && props.proxyAuthType === ':CONFIDENTIAL:'
                && props.appActivation.smartLaunchProxyInfo2Api?.clientAuthenticationMethod === 'SYMMETRIC' ? (
                    <FormControl fullWidth style={{ marginTop: '16px' }}>
                        <InputLabel>
                            Client Secret
                        </InputLabel>
                        <Input
                            name="smart2-client-secret"
                            value={props.appActivation.smartLaunchProxyInfo2Api?.clientSecret ?? ''}
                            disabled={!props.canEdit || props.estatus !== ':READY:'}
                            type={isSmart2ProxyClientSecretVisible ? 'text' : 'password'}
                            onClick={props.canEdit ? (e => {
                                let targetValue = (e.target as any).value;
                                if (targetValue?.localeCompare("<hidden>") === 0) {
                                    props.setAppActivation((prevState) => ({
                                        ...prevState,
                                        smartLaunchProxyInfo2Api: {
                                            ...(prevState.smartLaunchProxyInfo2Api || {}),
                                            clientSecret: "",
                                        },
                                    }));
                                }
                            }) : null}
                            onChange={(event) => {
                                props.setAppActivation((prevState) => ({
                                    ...prevState,
                                    smartLaunchProxyInfo2Api: {
                                        ...(prevState.smartLaunchProxyInfo2Api || {}),
                                        clientSecret: event.target.value,
                                    },
                                }))
                            }}
                            endAdornment={
                                <InputAdornment position="end" >
                                    <IconButton
                                        disabled={!props.canEdit || props.estatus !== ':READY:'}
                                        aria-label="toggle secret visibility"
                                        onClick={() => {
                                            if (props.appActivation.smartLaunchProxyInfo2Api?.clientSecret?.localeCompare("<hidden>") === 0) {
                                                props.setAppActivation((prevState) => ({
                                                    ...prevState,
                                                    smartLaunchProxyInfo2Api: {
                                                        ...(prevState.smartLaunchProxyInfo2Api || {}),
                                                        clientSecret: "",
                                                    },
                                                }));
                                            }
                                            setSmart2ProxyClientSecretVisible(!isSmart2ProxyClientSecretVisible)
                                        }}
                                        onMouseDown={(event) => event.preventDefault()}
                                    >
                                        {isSmart2ProxyClientSecretVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                ) : null}

            {props.authMode === ':SMART_2:'
                && props.proxyAuthType === ':CONFIDENTIAL:'
                && props.appActivation.smartLaunchProxyInfo2Api?.clientAuthenticationMethod === 'ASYMMETRIC' ? (
                    <TextField
                        name="smart2-app-name"
                        label="App Name"
                        value={props.appActivation.smartLaunchProxyInfo2Api?.appName ?? ''}
                        disabled={!props.canEdit || props.estatus !== ':READY:'}
                        fullWidth
                        style={{ marginTop: '16px' }}
                        onChange={(event) => {
                            props.setAppActivation((prevState) => ({
                                ...prevState,
                                smartLaunchProxyInfo2Api: {
                                    ...(prevState.smartLaunchProxyInfo2Api || {}),
                                    appName: event.target.value,
                                },
                            }))
                        }}
                    />
                ) : null}

            {props.authMode === ':SMART_2:'
                && props.proxyAuthType === ':CONFIDENTIAL:'
                && props.appActivation.smartLaunchProxyInfo2Api?.clientAuthenticationMethod === 'ASYMMETRIC' ? (
                    <div>
                        <Button
                            data-e2e="act_generate_smart2_key_pair"
                            disabled={(
                                !props.canEdit
                                || props.estatus !== ':READY:'
                                || !props.appActivation.smartLaunchProxyInfo2Api?.appName
                            )}
                            variant="contained"
                            color="primary"
                            style={{ display: 'block', marginTop: '16px', marginBottom: '16px' }}
                            onClick={async () => {
                                props.setEStatus(':WAITING:')
                                const url = `api/encryption/smart2`
                                const putDataObj = {
                                    accountId: props.appActivation.smartLaunchProxyInfo2Api?.accountId,
                                    appId: props.appActivation.appId,
                                    smartLaunchProxyInfo2Api: {
                                        clientId: props.appActivation.smartLaunchProxyInfo2Api?.clientId,
                                        appName: props.appActivation.smartLaunchProxyInfo2Api?.appName,
                                    },
                                    dataSourceId: props.appActivation.smartLaunchProxyInfo2Api?.dataSourceId,
                                    environmentId: props.appActivation.smartLaunchProxyInfo2Api?.environmentId,
                                    gatewayId: props.appActivation.gatewayId,
                                }
                                let res: {
                                data: TAppActivation['smartLaunchProxyInfo2Api']
                            }
                                try {
                                    res = await axiosWrapper(props.config.envApi, url, 'PUT', putDataObj)
                                }
                                catch(reason) {
                                    res = {
                                        data: {
                                            smartLaunchProxyId: 'ERROR_smartLaunchProxyId_' + Date.now(),
                                            publicKey: 'ERROR_publicKey_' + reason.toString(),
                                        },
                                    }
                                }
                                props.setAppActivation((prevState) => ({
                                    ...prevState,
                                    smartLaunchProxyInfo2Api: {
                                        ...(prevState.smartLaunchProxyInfo2Api || {}),
                                        smartLaunchProxyId: res.data.smartLaunchProxyId,
                                        publicKey: res.data.publicKey,
                                    },
                                }))
                                props.setEStatus(':READY:')
                            }}
                        >
                            Generate public/private key pair
                        </Button>
                        {props.dataValidation.appActivation?.publicPrivateKeyPairGenerated ? (
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'baseline',
                            }}>
                                <FormHelperText error={!!props.dataValidation.appActivation?.publicPrivateKeyPairGenerated}>
                                    {props.dataValidation.appActivation?.publicPrivateKeyPairGenerated ?? ''}
                                </FormHelperText>
                            </div>
                        ) : null}
                    </div>
                ) : null}

            {props.authMode === ':SMART_2:'
                && props.proxyAuthType === ':CONFIDENTIAL:'
                && props.appActivation.smartLaunchProxyInfo2Api?.clientAuthenticationMethod === 'ASYMMETRIC' ? (
                    <Button
                        data-e2e="act_download_pem"
                        disabled={
                            !props.canEdit
                            || props.estatus !== ':READY:'
                            || !props.appActivation.smartLaunchProxyInfo2Api?.smartLaunchProxyId
                            || !props.appActivation.smartLaunchProxyInfo2Api?.appName
                            || !props.appActivation.smartLaunchProxyInfo2Api?.publicKey
                        }
                        variant="contained"
                        color="primary"
                        style={{ display: 'block', marginTop: '16px', marginBottom: '16px' }}
                        onClick={async () => {
                            props.setEStatus(':WAITING:')
                            try {
                                const url = `api/encryption/smart2/${props.appActivation.smartLaunchProxyInfo2Api?.smartLaunchProxyId}`
                                const res: { data?: string } = await axiosWrapper(props.config.envApi, url, 'GET', null, {}, 'stream')
                                if (typeof res.data === 'string') {
                                    const element = document.createElement('a')
                                    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(res.data))
                                    element.setAttribute('download', 'pem')
                                    element.style.display = 'none'
                                    document.body.appendChild(element)
                                    element.click()
                                    document.body.removeChild(element)
                                }
                                else {
                                    throw new Error('Error: Could not find PEM string! "data" field is missing in the response or it is not of type "string"!')
                                }
                            }
                            catch (reason) {
                                consoleLogger.error(reason)
                            }
                            props.setEStatus(':READY:')
                        }}
                    >
                        Download PEM file
                    </Button>
                ) : null}

            {(props.authMode === ':SMART_2:'
                && props.proxyAuthType === ':CONFIDENTIAL:'
                && props.appActivation.smartLaunchProxyInfo2Api?.clientAuthenticationMethod === 'ASYMMETRIC') ? (
                    <TextField
                        name="smart2-token-url"
                        label="Token URL"
                        value={props.appActivation.smartLaunchProxyInfo2Api?.tokenUrl ?? ''}
                        disabled={!props.canEdit || props.estatus !== ':READY:'}
                        fullWidth
                        style={{ marginTop: '16px' }}
                        onChange={(event) => {
                            props.setAppActivation((prevState) => ({
                                ...prevState,
                                smartLaunchProxyInfo2Api: {
                                    ...(prevState.smartLaunchProxyInfo2Api || {}),
                                    tokenUrl: event.target.value,
                                },
                            }))
                        }}
                    />
                ) : null}
            {/* END more SMART 2 / confidential settings ------------------- */}

            {/* START more SMART 2 settings #2 ----------------------------- */}
            {props.authMode === ':SMART_2:' ? (
                <TextField
                    name="smart2-scopes"
                    label="Scopes"
                    value={props.appActivation.smartLaunchProxyInfo2Api?.scopes ?? ''}
                    disabled={!props.canEdit || props.estatus !== ':READY:'}
                    fullWidth
                    multiline
                    style={{ marginTop: '16px' }}
                    onChange={(event) => {
                        props.setAppActivation((prevState) => ({
                            ...prevState,
                            smartLaunchProxyInfo2Api: {
                                ...(prevState.smartLaunchProxyInfo2Api || {}),
                                scopes: event.target.value,
                            },
                        }))
                    }}
                />
            ) : null}
            {/* END more SMART 2 settings #2 ------------------------------- */}

            {/* START IKMR4Ext Practice Settings --------------- */}

            {props.isIKnowMedExtGateway ? (
                <FormControl
                    fullWidth
                    disabled={!props.canEdit || props.estatus !== ':READY:'}
                    style={{ marginTop: '16px' }}
                >
                    <InputLabel>
                        Secure Launch URL
                    </InputLabel>
                    <Select
                        data-e2e='activation-security-option'
                        value={props.appActivation.securityOption || 'NONE'}
                        onChange={(event) => {
                            props.setAppActivation((prevState) => ({
                                ...prevState,
                                securityOption: event.target.value as TActivationSecurityOption,
                                appActivationOktaPropertiesApi: event.target.value === 'OKTA' ? {
                                    ...(prevState.appActivationOktaPropertiesApi || {}),
                                    oktaTokenIssuer: '',
                                    permissionsLocation: '',
                                    permissionName: '',
                                } : undefined,
                            }))
                        }}
                    >
                        <MenuItem value="NONE">
                            NONE
                        </MenuItem>
                        <MenuItem value="OKTA">
                            OKTA
                        </MenuItem>
                        <MenuItem value="OAUTH2">
                            OAUTH2
                        </MenuItem>
                    </Select>
                </FormControl>
            ) : null}

            {props.isIKnowMedExtGateway && props.appActivation.securityOption === "OKTA" ? (
                <div>
                    <TextField
                        name="activation-okta-token-issuer"
                        data-e2e="activation-okta-token-issuer"
                        label="OKTA Server URL"
                        value={props.appActivation.appActivationOktaPropertiesApi?.oktaTokenIssuer ?? ''}
                        disabled={!props.canEdit || props.estatus !== ':READY:'}
                        fullWidth
                        style={{ marginTop: '16px' }}
                        error={!!props.dataValidation.appActivation.oktaTokenIssuer}
                        helperText={props.dataValidation.appActivation.oktaTokenIssuer}
                        onChange={(event) => {
                            props.setAppActivation((prevState) => ({
                                ...prevState,
                                appActivationOktaPropertiesApi: {
                                    ...prevState.appActivationOktaPropertiesApi,
                                    oktaTokenIssuer: event.target.value
                                },
                            }))
                        }}
                    />
                    <TextField
                        name="activation-permissions-location"
                        data-e2e="activation-permissions-location"
                        label="Permissions location"
                        value={props.appActivation.appActivationOktaPropertiesApi?.permissionsLocation ?? ''}
                        disabled={!props.canEdit || props.estatus !== ':READY:'}
                        fullWidth
                        style={{ marginTop: '16px' }}
                        error={!!props.dataValidation.appActivation.permissionsLocation}
                        helperText={props.dataValidation.appActivation.permissionsLocation}
                        onChange={(event) => {
                            props.setAppActivation((prevState) => ({
                                ...prevState,
                                appActivationOktaPropertiesApi: {
                                    ...prevState.appActivationOktaPropertiesApi,
                                    permissionsLocation: event.target.value
                                },
                            }))
                        }}
                    />
                    <TextField
                        name="activation-permissions-name"
                        data-e2e="activation-permissions-name"
                        label="Permissions name"
                        value={props.appActivation.appActivationOktaPropertiesApi?.permissionName ?? ''}
                        disabled={!props.canEdit || props.estatus !== ':READY:'}
                        fullWidth
                        style={{ marginTop: '16px' }}
                        error={!!props.dataValidation.appActivation.permissionName}
                        helperText={props.dataValidation.appActivation.permissionName}
                        onChange={(event) => {
                            props.setAppActivation((prevState) => ({
                                ...prevState,
                                appActivationOktaPropertiesApi: {
                                    ...prevState.appActivationOktaPropertiesApi,
                                    permissionName: event.target.value
                                },
                            }))
                        }}
                    />
                </div>
            ) : null}

            {props.isIKnowMedExtGateway ? (
                <PracticeList
                    isIKnowMedExtGateway={props.isIKnowMedExtGateway}
                    data={props.appActivation}
                    canEdit={props.canEdit}
                    isAppLaunchingWithinIKMG2={props.isAppLaunchingWithinIKMG2}
                    muiTheme={props.muiTheme}
                    setAppActivation={props.setAppActivation}
                    setIsAppLaunchingWithinIKMG2={props.setIsAppLaunchingWithinIKMG2}
                    dataValidation={props.dataValidation}
                />
            ) : null}

            {/* END IKMR4Ext Practice Settings ----------------- */}

            <textarea
                ref={appRegUrlRef}
                value={appRegUrl}
                readOnly
                style={{ width: 1, height: 1, margin: 0, padding: 0, border: 'none', opacity: 0 }}
            />
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ui: state.ui,
    ...ownProps,
})
export default connect(mapStateToProps)(EStep1)
