import * as React from "react";
import * as PropTypes from "prop-types";
import {readAndCompressImage} from "browser-image-resizer";
import {isEnvironmentAdmin} from "../../../../../lib/user-environment-permissions-helpers";
import {getPath} from "../../../../../lib/utils/";
import * as validateData from "../../../../../lib/validate-apps-data";
import uploadImage from "../../../../../lib/uploadImage";
import consoleLogger from "../../../../../lib/consoleLogger";
import {Card, CircularProgress, IconButton, Button, TextField, Switch, Tooltip, FormControlLabel} from "@material-ui/core";
import Header from "../../../../Widgets/Editor/Header/";
import AppRedirectUrls from "../../AppRedirectUrls/";
import AppSecurity from "../../AppSecurity/";
import ActionButtons from "./ActionButtons";
import EhrListingUrls from "../../EhrListingUrls";
import {CloudUpload, Edit, VpnLock, Info, FileCopy} from "@material-ui/icons";
import "./style.less";
import type { TProps as TEhrListingUrls } from '../../EhrListingUrls';
import ImageLoader from "../../../../Widgets/ImageLoader";

export default class extends React.Component<any, any> {
    public static propTypes = {
        muiTheme: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        ui: PropTypes.object.isRequired,

        onSetState: PropTypes.func.isRequired
    };

    public componentDidMount() {
        this.validateData({validateLogoUrl: false});
    }

    public componentDidUpdate(prevProps) {
        const data_string = JSON.stringify(this.props.state.data);
        const prevData_string = JSON.stringify(prevProps.state.data);
        if (data_string !== prevData_string) {
            this.validateData({validateLogoUrl: this.props.state.data.logoUrl !== prevProps.state.data.logoUrl});
        }
    }

    public render() {
        const actAsAdmin: boolean = isEnvironmentAdmin(this.props.login, this.props.location, this.props.config);

        const canEdit = this.props.state.mode === ":EDIT:";
        const isValid = Object.keys(this.props.state.dataValidation).reduce((acc, key) => acc && !this.props.state.dataValidation[key], true);

        return <Card classes={{root: "app-edit-card"}}>
            {this.renderClientId(this.props, canEdit, actAsAdmin)}

            <div style={{padding: "0 24px"}}>
                {this.renderLogo()}
                {this.renderLogoSourceEditor(canEdit)}
                {this.renderName(canEdit)}
                {this.renderAppId()}
                {this.renderAppProgram()}
                {this.renderDescription(canEdit)}
                {this.renderLogging(canEdit)}
                {this.renderLaunchUrl(canEdit)}
                {this.renderHealthUrl(canEdit)}
                <AppRedirectUrls{...this.props} canEdit={canEdit}/>
                <EhrListingUrls {...({ ...this.props, canEdit } as TEhrListingUrls)} />
                {this.renderScopes(canEdit)}
                <AppSecurity{...this.props} canEdit={canEdit}/>
            </div>

            <ActionButtons{...this.props} isValid={isValid}/>
        </Card>;
    }

    private renderClientId = (props, canEdit, actAsAdmin) => {
        const clientId = props.state.data.clientId || "";

        return <Header
            {...props}
            leftIcon={<IconButton classes={{root: "io-icon-button left-icon-button"}}>
                <Tooltip placement="bottom-start" title="This client ID is issued by interopIO. Use this client ID in the Smart App." classes={{tooltip: "io-tooltip"}}>
                    <VpnLock style={{color: props.muiTheme.palette.secondaryTextColor}}/>
                </Tooltip>
            </IconButton>}
            primaryText={<div style={{display: "flex"}}>
                <div style={{paddingTop: "4px"}}>
                    <strong>Client ID: </strong>
                    {clientId}
                </div>
                <div style={{marginLeft: "8px"}}>
                    <Tooltip title="Copy" placement="bottom" classes={{tooltip: "io-tooltip"}}>
                        <IconButton onClick={() => this.handleCopy()} classes={{root: "io-icon-button"}}>
                            <FileCopy/>
                        </IconButton>
                    </Tooltip>
                </div>
            </div>}
            rightIcon={(!canEdit && actAsAdmin)
                ? <Tooltip title="Edit" placement="bottom" classes={{tooltip: "io-tooltip"}}>
                    <IconButton data-qa="app-details-edit-button" onClick={() => props.onSetState({mode: ":EDIT:", secretReset: false})} classes={{root: "io-icon-button right-edit-button"}}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
                : null}
            style={{height: "56px"}}
        />;
    }

    private renderLogo = () => {
        let logo = null;
        if (this.props.state.dataValidation.logoUrl === ":LOADING:") {
            logo = <CircularProgress size={100}/>;
        } else if (!this.props.state.data.logoUrl || this.props.state.dataValidation.logoUrl) {
            logo = <ImageLoader src={"/console/img/app-logo-hint.png"} imgStyle={{maxHeight: "124px"}} config={this.props.config}/>
        } else {
            logo = <ImageLoader data-qa-app-details-uploaded-logo src={this.props.state.data.logoUrl} imgStyle={{maxHeight: "124px"}} config={this.props.config}/>
        }

        return <div style={{height: "156px", padding: "16px", textAlign: "center"}}>
            {logo}
        </div>;
    }

    private renderLogoSourceEditor = canEdit => {
        let dataValidationLogoUrl = this.props.state.dataValidation.logoUrl;
        if (dataValidationLogoUrl === ":LOADING:") {
            dataValidationLogoUrl = "";
        }

        const errorMessagePadding = dataValidationLogoUrl ? "8px" : 0;
        const errorMessageTransform = dataValidationLogoUrl ? "scaleY(1)" : "scaleY(0)";
        let errorMessageBackground = this.props.ui.xtheme.palette.colorRedDark;
        let errorMessage = dataValidationLogoUrl;

        return canEdit
            ? <div>
                <Card style={{padding: "8px", background: this.props.ui.xtheme.palette.colorBgndGreyLight}}>
                    <div>
                        <p>
                            Before you upload your images, check out these
                            image recommendations to ensure they’ll look great:
                        </p>
                        <ul>
                            <li>
                                <strong>Format</strong>&nbsp;
                                When you save images, use PNG (.png), JPEG (.jpg/.jpeg),
                                TIFF (.tif/.tiff), GIF (.gif), BMP (.bmp) or SVG (.svg) format.
                            </li>
                            <li>
                                <strong>File extension</strong>&nbsp;
                                The image file extension may not correspond to its format.
                            </li>
                            <li>
                                <strong>Image Resolution</strong>&nbsp;
                                We support images up to 2500x1500 px
                            </li>
                        </ul>
                        <div style={{paddingBottom: "8px", textAlign: "center"}}>
                            <input id="contained-button-app-file" type="file" accept="image/*" style={{display: "none"}}
                                onChange={async event => {
                                    this.props.onSetState({
                                        dataValidation: {
                                            ...this.props.state.dataValidation,
                                            logoUrl: ":LOADING:"
                                        }
                                    });
                                    const IMAGE_WIDTH = 296 * 2;
                                    const IMAGE_HEIGHT = 124 * 2;
                                    const MAX_WIDTH = 2500;
                                    const MAX_HEIGHT = 1500;

                                    // *** [1] Check if `window.URL` is supported by the browser
                                    const windowURL = window.URL || (window as any).webkitURL;
                                    if (!windowURL) return;

                                    // *** [2] Check if there is a file selected
                                    // The file could be missing when the user hits `Escape`, for example
                                    let file: File = getPath(event, "target.files.0");
                                    if (!file) {
                                        return;
                                    }

                                    // *** [3] Check and set the image type
                                    // Since we have set `accept="image/*"` for the <input />,
                                    // we do not really need this type of check,
                                    // but it stays here just in case
                                    if (!file.type || !file.type.match("image.*")) {
                                        return this.props.onSetState((prevState) => ({
                                            dataValidation: {
                                                ...prevState.dataValidation,
                                                logoUrl: "The file is not an image"
                                            }
                                        }));
                                    }

                                    // *** [4] Create an HTMLImageElement
                                    let img: HTMLImageElement = new Image()
                                    img.src = windowURL.createObjectURL(file);

                                    img.onload = async () => {
                                        // *** [5] Get image dimensions
                                        const width = img.naturalWidth
                                        const height = img.naturalHeight

                                        // Call the method `revokeObjectURL` when you've finished using an object URL
                                        // to let the browser know not to keep the reference to the file any longer
                                        windowURL.revokeObjectURL(img.src);

                                        if (!width || !height) {
                                            return;
                                        }

                                        // *** [6] Check maximum size
                                        if (width > MAX_WIDTH || height > MAX_HEIGHT) {
                                            return this.props.onSetState((prevState) => ({
                                                dataValidation: {
                                                    ...prevState.dataValidation,
                                                    logoUrl: `The image is too large. The maximum allowed size is ${MAX_WIDTH}x${MAX_HEIGHT} pixels`
                                                }
                                            }));
                                        }
                                        const name = file.name.split(".")[0];

                                        file = await readAndCompressImage(file, {
                                            mimeType: "image/png",
                                            quality: 0.75,
                                            maxWidth: IMAGE_WIDTH,
                                            maxHeight: IMAGE_HEIGHT,
                                            autoRotate: true,
                                            debug: false
                                        });

                                        let bodyFormData = new FormData();
                                        bodyFormData.append("file", file, `${name}.png`);

                                        const response = await uploadImage(this.props.config, bodyFormData);
                                        if (response && response.status < 300 && response.data) {
                                            try {
                                                this.props.onSetState({
                                                    data: {
                                                        ...this.props.state.data,
                                                        logoUrl: response.data.fileUrl,
                                                        logoId: response.data.fileId
                                                    }
                                                });
                                            } catch (reason) {
                                                consoleLogger.log("::: reason:", reason);
                                            }
                                        }
                                    }
                                }}
                            />
                            <label htmlFor="contained-button-app-file">
                                <Button variant="contained" data-qa-app-details-edit-upload-image-button color="primary" component="span"  startIcon={<CloudUpload/>}>
                                    Upload Image
                                </Button>
                            </label>
                        </div>
                    </div>
                </Card>

                <div style={{
                    marginTop: "16px",
                    padding: errorMessagePadding,
                    transform: errorMessageTransform,
                    transformOrigin: "top",
                    borderRadius: "2px",
                    background: errorMessageBackground,
                    color: "#fff",
                    textAlign: "center",
                    transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)"
                }}>
                    {errorMessage}
                </div>
            </div>
            : null;
    }

    private renderName = canEdit => {
        let props = {
            "data-qa": "app-details-name-edit",
            name: "name",
            fullWidth: true,
            value: this.props.state.data.name || "",
            label: this.props.state.dataValidation.name || "Display Name",
            error: !!this.props.state.dataValidation.name,
            placeholder: canEdit ? "Type a name for the app" : undefined,
            classes: {root: `input-margin-bottom${canEdit ? "" : " disabled-text-field"}`},
            onChange: canEdit
                ? e => {
                    this.props.onSetState({data: {...this.props.state.data, name: e.target.value}})
                }
                : undefined,
            disabled: !canEdit
        };

        return <TextField {...props} />;
    }

    private renderAppId = () => {
        return <TextField classes={{root: "input-margin-bottom disabled-text-field"}} name="appId" value={this.props.state.data.appId || ""} disabled label="App ID" fullWidth/>;
    }

    private renderAppProgram = () => {
        return <TextField classes={{root: "input-margin-bottom disabled-text-field"}} name="appProgram" value={this.props.state.data.appProgram || ""} disabled label="App Program" fullWidth/>;
    }

    private renderDescription = canEdit => {
        let props = {
            name: "description",
            value: this.props.state.data.description || "",
            label: "Description",
            fullWidth: true,
            multiline: true,
            classes: {root: `input-margin-bottom${canEdit ? "" : " disabled-text-field"}`},
            onChange: canEdit
                ? e => {
                    this.props.onSetState({
                        data: {
                            ...this.props.state.data,
                            description: e.target.value
                        }
                    });
                }
                : undefined,
            disabled: !canEdit
        }

        return <TextField {...props} />;
    }

    private renderLogging = canEdit => {
        let props = {
            checked: this.props.state.data.logViewer,
            disabled: !canEdit,
            onChange: canEdit ? e => this.props.onSetState({data: {...this.props.state.data, logViewer: e.target.checked}}) : undefined
        };
        let tooltip = <span style={{display: "inline-block", width: "80px", padding: "8px 0", lineHeight: "normal", whiteSpace: "normal"}}>Read more</span>;

        return <div style={{marginBottom: "16px"}}>
            <FormControlLabel label={"Enable Application Logging" + (!canEdit ? ` (${this.props.state.data.logViewer ? "Enabled" : "Disabled"})` : "")} labelPlacement='top'
                control={<Switch color="primary" {...props} />} style={{justifyContent: "flex-start", alignItems: "flex-start", marginLeft: "0"}}/>
            <a href="https://support.interopio.com/hc/en-us/articles/360041549832" target="_blank" style={{display: "inline-block"}}>
                <Tooltip title={tooltip} placement="top-start" classes={{tooltip: "io-tooltip"}}>
                    <IconButton>
                        <Info/>
                    </IconButton>
                </Tooltip>
            </a>
        </div>;
    };

    private renderLaunchUrl = canEdit => {
        let props = {
            name: "launchUrl",
            value: this.props.state.data.launchUrl || "",
            placeholder: canEdit ? "Type the app launch URI" : undefined,
            label: this.props.state.dataValidation.launchUrl || "App Launch URI",
            fullWidth: true,
            classes: {root: `input-margin-bottom${canEdit ? "" : " disabled-text-field"}`},
            onChange: e => this.props.onSetState({isNextWStepEnabled: false, data: {...this.props.state.data, launchUrl: e.target.value}}),
            disabled: !canEdit
        };

        return <TextField {...props} />;
    }
    private renderHealthUrl = canEdit => {
        let props = {
            name: "healthUrl",
            value: this.props.state.data.healthUrl || "",
            placeholder: canEdit ? "Type the app health URI" : undefined,
            label: this.props.state.dataValidation.healthUrl || "App Health URI",
            fullWidth: true,
            classes: {root: `input-margin-bottom${canEdit ? "" : " disabled-text-field"}`},
            onChange: e => this.props.onSetState({isNextWStepEnabled: false, data: {...this.props.state.data, healthUrl: e.target.value}}),
            disabled: !canEdit
        };

        return <TextField {...props}/>;
    }
    private renderScopes = (canEdit) => {
        const errorObj = this.props.apps?.selected?.error ?? {};
        const isInvalid = errorObj.hasOwnProperty('invalidFields') && errorObj.invalidFields.includes('scopes');
        const errorMessage = isInvalid && errorObj.hasOwnProperty('message') ? errorObj.message : null;
        return (canEdit) ? (
            <TextField
                name="scopes"
                value={this.props.state.data.scopes || ''}
                placeholder="Space separated list of scopes eg. 'launch patient/*.* openid profile'"
                label={this.props.state.dataValidation.scopes || 'Scopes'}
                fullWidth
                error={errorMessage !== null ? errorMessage : null}
                helperText={errorMessage}
                multiline
                onChange={(event) => {
                    this.props.onSetState((prevState) => ({
                        data: {
                            ...prevState.data,
                            scopes: event.target.value,
                        },
                    }))
                }}
            />
        ) : (
            <div>
                <div style={{
                    marginBottom: '8px',
                    fontSize: '12px',
                    color: this.props.muiTheme.palette.disabledColor,
                }}>
                    Scopes
                </div>
                <div style={{ color: this.props.muiTheme.palette.textColor }}>
                    {this.props.state.data.scopes}
                </div>
            </div>
        )
    }

    private handleCopy: Function = () => {
        const src = encodeURI(this.props.state.data.clientId || "");
        const container = document.createElement("textarea");
        container.innerHTML = src;
        document.body.appendChild(container);
        container.select();
        document.execCommand("copy");

        document.body.removeChild(container);

        this.props.generalMessageSet({
            type: ":INFO_SNACKBAR:",
            title: "Copied to Clipboard"
        });
    }

    private validateData = ({validateLogoUrl}) => {
        const dataValidation = validateData.onEditDetails(this.props.state);
        const newRedirectUrl = this.props.state.data.newRedirectUrl;
        dataValidation["newRedirectUrlNotSaved"] = !!newRedirectUrl || newRedirectUrl === 0;

        this.props.onSetState(
            prevState => {
                return {
                    dataValidation: {
                        ...prevState.dataValidation,
                        ...dataValidation,
                        logoUrl: ":LOADING:"
                    }
                };
            },
            () => {
                if (validateLogoUrl) {
                    validateData.onEditLogoUrlAsync(this.props)
                        .then((logoUrl) => this.props.onSetState((prevState) => ({
                            dataValidation: {
                                ...prevState.dataValidation,
                                logoUrl
                            }
                        })));
                } else {
                    this.props.onSetState((prevState) => ({
                        dataValidation: {
                            ...prevState.dataValidation,
                            logoUrl: ""
                        }
                    }));
                }
            }
        );
    }
}
